import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Context } from "../Context/AuthContext";
import Dashboard from "../pages/Dashboard";
import { List } from "../pages/List";
import { Login } from "../pages/login";

export default function RoutesAdmin(){

    const {authenticated} = useContext(Context)

    return(

            <Routes>
                <Route  path='/' element={<Login/>} />
                <Route  path='/dashboard' element={authenticated ? <Dashboard/> : <Navigate to="/"/>}/>
                <Route  path='/list' element={authenticated ? <List/> : <Navigate to="/"/>}/>
            </Routes>
      
    )
}
