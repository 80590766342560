import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import loading from '../../img/load.gif';

import { Context } from "../../Context/AuthContext";


import api from '../../config/configApi';

export const Login = () => {

    const navigate = useNavigate() 

    const {authenticated, signIn} = useContext(Context)

    console.log("Na tela de login o usuario esta " + authenticated)

     


    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [status, setStatus] = useState({
        type: '',
        mensagem: '',
        loading: false

    });

    //pego o valor que user ja tem, em seguida pego o que o usuario digitou no atributo name e por fim seto o valor nas variaveis email e password.
    const valorInput = e => setUser({ ...user, [e.target.name]: e.target.value });

    const loginSubmit = async e => {
        e.preventDefault();
        //console.log(user.email);
        setStatus({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json'
        }

        await api.post("/user-login", user, { headers })
            .then((response) => {
                //console.log(response);
                setStatus({
                    /* type: 'success',
                    mensagem: response.data.mensagem, */
                    loading: false
                })
                localStorage.setItem('token',JSON.stringify(response.data.token))
                signIn(true)
                return navigate('/dashboard')
            }).catch((err) => {

                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem,
                        loading: false

                    })
                } else {

                    setStatus({
                        type: 'error',
                        mensagem: 'Tente mais tarde!',
                        loading: false
                    })

                }

            });

    }



    return (

        <body className="corpo">
        <div class="text-center">
            <main class="form-signin">
                <form   onSubmit={loginSubmit}>
                    <img class="mb-4" src="/img/logo.png" alt="" width="140" height="90" />

                    <div class="form-floating">
                        <input required type="email" class="form-control" name="email" placeholder="Digite o e-mail" onChange={valorInput} />
                        <label for="floatingInput">Digite seu e-mail</label>
                    </div>
                    <div class="form-floating">
                        <input required type="password" class="form-control" name="password" placeholder="Digite a senha" onChange={valorInput} />
                        <label for="floatingPassword">Digite sua senha</label>
                    </div>

                    <div class="checkbox mb-3">
                        {status.type === 'error' ? <p>{status.mensagem}</p> : ""}
                        {status.type === 'success' ? <p >{status.mensagem}</p> : ""}
                        {status.loading ? <img width={100} src={loading}></img> : ""}
                        
                    </div>

                    {status.loading ? <button class="w-100 btn btn-lg btn-primary" type="submit" disabled>Acessar</button> : <button class="w-100 btn btn-lg btn-primary" type="submit" >Acessar</button>}
                    
                    <a href="/add-user">Casdastre-se</a> - <a href="/recover-pass">Esqueci a Senha</a>
                    <p class="mt-5 mb-3 text-muted">&copy; SESI/SENAI - 2024</p>
                </form>
            </main>
        </div>
        </body>
    )
}