import React, { useContext } from "react";


import { Context } from "../../Context/AuthContext";

export const List = () =>{

const { authenticated,handleLogout } = useContext(Context)

  console.log("Na tela de Dashboard o usuario esta " + authenticated)

  const token = localStorage.getItem('token')

  return(
    <div>
        <h1>Listar</h1>
        <p>Token: {token}</p>
        <button type="button" onClick={handleLogout}>Sair</button>
    </div>
  )

}