import React, { createContext, useEffect, useState } from "react";
import api from '../config/configApi';
const Context = createContext()



function AuthProvider({children}){

    const [authenticated, setauthenticated] = useState(false)

    useEffect(()=>{

        const getLogin = async() =>{

            const token = localStorage.getItem('token')

            if(token){
                api.defaults.headers.Authorization = `Bearer ${(token)}`
                setauthenticated(true)
            }
        }

        getLogin()

    },[])

    async function signIn(situacao){
        setauthenticated(true)
    }

    function handleLogout(){

        setauthenticated(false)
        localStorage.removeItem('token')
        api.defaults.headers.Authorization = undefined
    }

    return(
        <Context.Provider value={{authenticated, signIn, handleLogout}}>
            {children}
        </Context.Provider>
    )
}

export { AuthProvider, Context };
